export default {
  // Endpoints
  apiEndpoint: 'https://api-dashboard.phoenix-widget.com',
  loginEndpoint: 'https://api-dashboard.phoenix-widget.com/token',
  registerEndpoint: 'https://api-dashboard.phoenix-widget.com/accounts',
  refreshEndpoint: 'https://api-dashboard.phoenix-widget.com/token',
  logoutEndpoint: 'https://api-dashboard.phoenix-widget.com/revoke',
  campaignsEndpoint: 'https://api-dashboard.phoenix-widget.com/campaigns',
  bannersEndpoint: 'https://api-dashboard.phoenix-widget.com/banners',
  bannerListStatsEndpoint: 'https://api-dashboard.phoenix-widget.com/banner-list-stats',
  usersEndpoint: 'https://api-dashboard.phoenix-widget.com/accounts',
  sitesEndpoint: 'https://api-dashboard.phoenix-widget.com/sites',
  sitestatsEndpoint: 'https://api-dashboard.phoenix-widget.com/sitestats',
  sitestatsV2Endpoint: 'https://api.phoenix-widget.com/api/dash/stats/sites',
  sitestatsEcpmEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/partners/sites',
  sitestatsRevenueEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/sites/revenue',
  statsBannersSitesEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/banners/sites',
  sitestatsRevenueTotalEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/sites/revenue/total',
  siteesstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/siteesstats',
  siteclicksourcesEndpoint: 'https://api-dashboard.phoenix-widget.com/siteclicksources',
  siteclickesstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/siteclickesstats',
  promotionsEndpoint: 'https://api-dashboard.phoenix-widget.com/promotions',
  promotionpagesEndpoint: 'https://api-dashboard.phoenix-widget.com/promotionpages',
  promotionpagestatsEndpoint: 'https://api-dashboard.phoenix-widget.com/promotionpagestats',
  widgetstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/widgetstats',
  bannerstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/bannerstats',
  campaignstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignstats',
  campaignstatsV2Endpoint: 'https://api.phoenix-widget.com/api/dash/stats/banners/campaigns',
  statsBannersCampaignsTotalEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/banners/campaigns/total',
  campaignstatsEcpmEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/partners/sites',
  statsPartnersCampaignsTotalEndpoint: 'https://api.phoenix-widget.com/api/dash/stats/partners/campaigns/total',
  campaignesstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignesstats',
  toparticlesEndpoint: 'https://api-dashboard.phoenix-widget.com/sitetoparticles',
  campaignclicksourcesEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignclicksources',
  campaignPostbackConversionsEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignpostbackstats',
  campaignclickesstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignclickesstats',
  campaignpixelclicksEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignpixelclicks',
  campaigngeoclicksEndpoint: 'https://api-dashboard.phoenix-widget.com/campaigngeoclicks',
  impressionstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/impressionstats',
  pixelsEndpoint: 'https://api-dashboard.phoenix-widget.com/pixels',
  agenciesEndpoint: 'https://api-dashboard.phoenix-widget.com/agencies',
  partnersEndpoint: 'https://api-dashboard.phoenix-widget.com/partners',
  partnerstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/partnerstats',
  dsppartnersEndpoint: 'https://api-dashboard.phoenix-widget.com/dsppartners',
  dsppartnerstatsEndpoint: 'https://api-dashboard.phoenix-widget.com/dsppartnerstats',
  currenciesEndpoint: 'https://api-dashboard.phoenix-widget.com/currencies',
  reportsEndpoint: 'https://api-dashboard.phoenix-widget.com/reports',
  reportsexportEndpoint: 'https://api-dashboard.phoenix-widget.com/reportsexport',
  widgetsEndpoint: 'https://api-dashboard.phoenix-widget.com/widgets',
  uploadEndpoint: 'https://api-dashboard.phoenix-widget.com/upload',
  convertEndpoint: 'https://api-dashboard.phoenix-widget.com/parser/convert',
  criteostatsapiEndpoint: 'https://api-dashboard.phoenix-widget.com/criteostatsapi',
  notificationsEndpoint: 'https://api-dashboard.phoenix-widget.com/notifications',
  parserTest: 'https://api-dashboard.phoenix-widget.com/parser/convert',
  widgetPreview: 'https://api.phoenix-widget.com/api/v1/preview',
  widgetPreviewV2: 'https://api.phoenix-widget.com/api/v2/preview',
  importAdsenseIncomesEndpoint: 'https://api-dashboard.phoenix-widget.com/upload-adsense-incomes',
  approveAdsenseIncomesEndpoint: 'https://api-dashboard.phoenix-widget.com/approve-adsense-incomes ',
  adsenseIncomesEndpoint: 'https://api-dashboard.phoenix-widget.com/adsense-incomes',
  prebidPartnersEndpoint: 'https://api-dashboard.phoenix-widget.com/prebidpartners',
  prebidPartnersStatsEndpoint: 'https://api-dashboard.phoenix-widget.com/prebidpartnerstats',
  dbdEndpoint: 'https://api-dashboard.phoenix-widget.com/dbd',
  siteRssTestEndpoint: 'https://api.phoenix-widget.com/api/dash/feeds',
  siteNameByIdEndpoint: 'https://api-dashboard.phoenix-widget.com/sitename-by-id',
  partnerNameByIdEndpoint: 'https://api-dashboard.phoenix-widget.com/partnername-by-id',
  campaignNameByIdEndpoint: 'https://api-dashboard.phoenix-widget.com/campaignname-by-id',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'refresh_token',
}
