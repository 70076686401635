import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            if (this.getToken() !== undefined && this.getRefreshToken() !== undefined) {
              this.refreshTokenPnx().then(data => {
                this.isAlreadyFetchingAccessToken = false
                this.setToken(data.access_token)
                this.setRefreshToken(data.refresh_token)
                this.onAccessTokenFetched(data.access_token)
              })
            } else {
              this.clearLocalStorage()
            }
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  clearLocalStorage() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('userData')
  }

  setRefreshToken(value) {
    if (value !== undefined) {
      localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    } else {
      this.clearLocalStorage()
      const refUrl = window.location.pathname
      window.location = `/?redirect=${refUrl}`
    }
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint,
      new URLSearchParams(...args), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  widgets() {
    return this.axiosIns.get(this.jwtConfig.widgetsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getWidget(id) {
    const widgetsEndpoint = `${this.jwtConfig.widgetsEndpoint}/${id}`
    return this.axiosIns.get(widgetsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getSiteWidgets(params) {
    return this.axiosIns.get(this.jwtConfig.widgetsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  setWidget(id, widget) {
    const widgetsEndpoint = `${this.jwtConfig.widgetsEndpoint}/${id}`
    return this.axiosIns.put(widgetsEndpoint, {
      widget,
    })
  }

  createWidget(widget) {
    const widgetsEndpoint = `${this.jwtConfig.widgetsEndpoint}`
    return this.axiosIns.post(widgetsEndpoint, {
      widget,
    })
  }

  campaigns() {
    return this.axiosIns.get(this.jwtConfig.campaignsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  accountCampaigns(id) {
    return this.axiosIns.get(`${this.jwtConfig.campaignsEndpoint}?account=${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getAgencyMembers(params) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  addAgencyMember(id, params) {
    const agenciesEndpoint = `${this.jwtConfig.agenciesEndpoint}/${id}/addmember`
    return this.axiosIns.post(agenciesEndpoint, {
      ...params,
    })
  }

  removeAgencyMember(id, params) {
    const agenciesEndpoint = `${this.jwtConfig.agenciesEndpoint}/${id}/removemember`
    return this.axiosIns.post(agenciesEndpoint, {
      ...params,
    })
  }

  getCampaign(id) {
    const campaignsEndpoint = `${this.jwtConfig.campaignsEndpoint}/${id}`
    return this.axiosIns.get(campaignsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getCampaignPayments(id) {
    const userEndpoint = `${this.jwtConfig.campaignsEndpoint}/${id}/payments`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  campaignPay(payment) {
    const userEndpoint = `${this.jwtConfig.campaignsEndpoint}/${payment.campaign_id}/payments`
    return this.axiosIns.post(userEndpoint, {
      payment,
    })
  }

  deleteCampaign(id) {
    const campaignsEndpoint = `${this.jwtConfig.campaignsEndpoint}/${id}`
    return this.axiosIns.delete(campaignsEndpoint)
  }

  getCampaignBanners(params) {
    return this.axiosIns.get(`${this.jwtConfig.bannersEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignListStats(params) {
    return this.axiosIns.get(`${this.jwtConfig.bannerListStatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getBanner(id) {
    const bannersEndpoint = `${this.jwtConfig.bannersEndpoint}/${id}`
    return this.axiosIns.get(bannersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setBanner(id, banner) {
    const bannersEndpoint = `${this.jwtConfig.bannersEndpoint}/${id}`
    return this.axiosIns.put(bannersEndpoint, {
      banner,
    })
  }

  uploadBanner(banner) {
    const { bannersEndpoint } = this.jwtConfig
    return this.axiosIns.post(bannersEndpoint, {
      banner,
    })
  }

  uploadBannerImg(id, formData) {
    const uploadEndpoint = `${this.jwtConfig.uploadEndpoint}/${id}?type=edit&kind=image`
    return this.axiosIns.post(uploadEndpoint, formData)
  }

  uploadBannersImg(id, formData) {
    const uploadEndpoint = `${this.jwtConfig.uploadEndpoint}/${id}?type=new&kind=image`
    return this.axiosIns.post(uploadEndpoint, formData)
  }

  copyBannerImg(id) {
    const bannersEndpoint = `${this.jwtConfig.bannersEndpoint}/copy/${id}`
    return this.axiosIns.post(bannersEndpoint, {})
  }

  deleteBannerImg(id) {
    const bannersEndpoint = `${this.jwtConfig.bannersEndpoint}/${id}`
    return this.axiosIns.delete(bannersEndpoint)
  }

  uploadBannerVideo(id, formData) {
    const uploadEndpoint = `${this.jwtConfig.uploadEndpoint}/${id}?type=edit&kind=video`
    return this.axiosIns.post(uploadEndpoint, formData)
  }

  getBannerStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.bannerstatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignClickSources(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignclicksourcesEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignPostbackConversion(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignPostbackConversionsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignClickesStats(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignclickesstatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignPixelClicks(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignpixelclicksEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignGeoClicks(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaigngeoclicksEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignstatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignStatsByTypeV2(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignstatsV2Endpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getStatsBannersCampaignsTotal(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.statsBannersCampaignsTotalEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignStatsEcpm(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignstatsEcpmEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getPartnersCampaignsTotal(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.statsPartnersCampaignsTotalEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignEsStatsByType(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.campaignesstatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getCampaignImpressionStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.impressionstatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  setCampaign(id, params) {
    const campaignsEndpoint = `${this.jwtConfig.campaignsEndpoint}/${id}`
    return this.axiosIns.put(campaignsEndpoint, {
      ...params,
    })
  }

  createCampaign(params) {
    const campaignsEndpoint = `${this.jwtConfig.campaignsEndpoint}`
    return this.axiosIns.post(campaignsEndpoint, {
      ...params,
    })
  }

  importIps(id, formData) {
    const campaignsEndpoint = `${this.jwtConfig.campaignsEndpoint}/${id}/import-ips`
    return this.axiosIns.post(
      campaignsEndpoint,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  }

  users(params) {
    return this.axiosIns.get(this.jwtConfig.usersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      ...params,
    })
  }

  newUser(params) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}`
    return this.axiosIns.post(userEndpoint, {
      ...params,
    })
  }

  getUser(id) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getUserPayments(id) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}/payments`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getUserTransaction(id, { page, limit }) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}/transactions?page=${page}&limit=${limit}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  userPay(payment) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${payment.user_id}/payments`
    return this.axiosIns.post(userEndpoint, {
      payment,
    })
  }

  setUser(id, params) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}`
    return this.axiosIns.put(userEndpoint, {

      ...params,
    })
  }

  setUserPassword(id, params) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}/changepassword`
    return this.axiosIns.post(userEndpoint, {
      ...params,
    })
  }

  setUserRoles(id, params) {
    const userEndpoint = `${this.jwtConfig.usersEndpoint}/${id}/changeroles`
    return this.axiosIns.post(userEndpoint, {
      ...params,
    })
  }

  sites(params = null) {
    return this.axiosIns.get(this.jwtConfig.sitesEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  accountSites(id) {
    return this.axiosIns.get(`${this.jwtConfig.sitesEndpoint}?account=${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getSite(id) {
    const userEndpoint = `${this.jwtConfig.sitesEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setSite(id, params) {
    const sitesEndpoint = `${this.jwtConfig.sitesEndpoint}/${id}`
    return this.axiosIns.put(sitesEndpoint, {
      ...params,
    })
  }

  createSite(params) {
    const sitesEndpoint = `${this.jwtConfig.sitesEndpoint}`
    return this.axiosIns.post(sitesEndpoint, {
      ...params,
    })
  }

  testSiteParser(params) {
    const parserTest = `${this.jwtConfig.parserTest}`
    return this.axiosIns.post(parserTest, {
      ...params,
    })
  }

  testSiteRss(params) {
    const { siteRssTestEndpoint } = this.jwtConfig
    return this.axiosIns.get(`${siteRssTestEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStats(id, type, date, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitesEndpoint}/${id}/stats/${type}/${date}/${date}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getWidgetStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.widgetstatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitestatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStatsV2ByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitestatsV2Endpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStatsEcpm(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitestatsEcpmEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStatsRevenue(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitestatsRevenueEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getStatsBannersSites(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.statsBannersSitesEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteStatsRevenueTotal(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.sitestatsRevenueTotalEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteEStats(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.siteesstatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteClickSources(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.siteclicksourcesEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteClickesStats(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.siteclickesstatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSiteTopArticles(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.toparticlesEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getSitePromotions(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.promotionsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getPromotion(id) {
    const promotionsEndpoint = `${this.jwtConfig.promotionsEndpoint}/${id}`
    return this.axiosIns.get(promotionsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setPromotion(id, promotion) {
    const promotionsEndpoint = `${this.jwtConfig.promotionsEndpoint}/${id}`
    return this.axiosIns.put(promotionsEndpoint, {
      promotion,
    })
  }

  createPromotion(promotion) {
    const promotionsEndpoint = `${this.jwtConfig.promotionsEndpoint}`
    return this.axiosIns.post(promotionsEndpoint, {
      promotion,
    })
  }

  getPromotionPages(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.promotionpagesEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getPromotionPage(id) {
    const promotionpagesEndpoint = `${this.jwtConfig.promotionpagesEndpoint}/${id}`
    return this.axiosIns.get(promotionpagesEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setPromotionPage(id, promotionpage) {
    const promotionsEndpoint = `${this.jwtConfig.promotionpagesEndpoint}/${id}`
    return this.axiosIns.put(promotionsEndpoint, {
      promotionpage,
    })
  }

  createPromotionPage(promotionpage) {
    const promotionsEndpoint = `${this.jwtConfig.promotionpagesEndpoint}`
    return this.axiosIns.post(promotionsEndpoint, {
      promotionpage,
    })
  }

  deletePromotionPage(id) {
    const promotionpagesEndpoint = `${this.jwtConfig.promotionpagesEndpoint}/${id}`
    return this.axiosIns.delete(promotionpagesEndpoint)
  }

  deletePromotion(id) {
    const promotionsEndpoint = `${this.jwtConfig.promotionsEndpoint}/${id}`
    return this.axiosIns.delete(promotionsEndpoint)
  }

  deleteEntity(entity, id) {
    const deleteEndpoint = `${this.jwtConfig.apiEndpoint}/${entity}/${id}`
    return this.axiosIns.delete(deleteEndpoint)
  }

  uploadPromotionPageImg(id, formData) {
    const uploadEndpoint = `${this.jwtConfig.uploadEndpoint}/${id}?type=promotion`
    return this.axiosIns.post(uploadEndpoint, formData)
  }

  parserConvert(params = null) {
    return this.axiosIns.post(this.jwtConfig.convertEndpoint, {
      ...params,
    })
  }

  getPromotionPageStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.promotionpagestatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  pixels() {
    return this.axiosIns.get(this.jwtConfig.pixelsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  accountPixels(id) {
    return this.axiosIns.get(`${this.jwtConfig.pixelsEndpoint}?user_id=${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getPixel(id) {
    const userEndpoint = `${this.jwtConfig.pixelsEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setPixel(id, params) {
    const pixelsEndpoint = `${this.jwtConfig.pixelsEndpoint}/${id}`
    return this.axiosIns.put(pixelsEndpoint, {
      ...params,
    })
  }

  createPixel(pixel) {
    const pixelsEndpoint = `${this.jwtConfig.pixelsEndpoint}`
    return this.axiosIns.post(pixelsEndpoint, {
      pixel,
    })
  }

  deletePixel(id) {
    const pixelsEndpoint = `${this.jwtConfig.pixelsEndpoint}/${id}`
    return this.axiosIns.delete(pixelsEndpoint)
  }

  agencies() {
    return this.axiosIns.get(this.jwtConfig.agenciesEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  accountAgencies(id) {
    return this.axiosIns.get(`${this.jwtConfig.agenciesEndpoint}?account=${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getAgency(id) {
    const userEndpoint = `${this.jwtConfig.agenciesEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  createAgency(params) {
    return this.axiosIns.post(this.jwtConfig.agenciesEndpoint, {
      ...params,
    })
  }

  setAgency(id, params) {
    const agenciesEndpoint = `${this.jwtConfig.agenciesEndpoint}/${id}`
    return this.axiosIns.put(agenciesEndpoint, {
      ...params,
    })
  }

  setAgencyMember(id, params) {
    const agenciesEndpoint = `${this.jwtConfig.agenciesEndpoint}/${id}/settings`
    return this.axiosIns.post(agenciesEndpoint, {
      ...params,
    })
  }

  partners() {
    return this.axiosIns.get(this.jwtConfig.partnersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getPartner(id) {
    const userEndpoint = `${this.jwtConfig.partnersEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getPartnerPreview(id, params) {
    const partnersEndpoint = `${this.jwtConfig.partnersEndpoint}/${id}/preview`
    return this.axiosIns.post(partnersEndpoint, {
      ...params,
    })
  }

  setPartner(id, params) {
    const partnersEndpoint = `${this.jwtConfig.partnersEndpoint}/${id}`
    return this.axiosIns.put(partnersEndpoint, {
      ...params,
    })
  }

  createPartner(partner) {
    const partnersEndpoint = `${this.jwtConfig.partnersEndpoint}`
    return this.axiosIns.post(partnersEndpoint, {
      partner,
    })
  }

  getPartnerStatsByType(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.partnerstatsEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  dsppartners() {
    return this.axiosIns.get(this.jwtConfig.dsppartnersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getDsppartner(id) {
    const userEndpoint = `${this.jwtConfig.dsppartnersEndpoint}/${id}`
    return this.axiosIns.get(userEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setDsppartner(id, params) {
    const dsppartnersEndpoint = `${this.jwtConfig.dsppartnersEndpoint}/${id}`
    return this.axiosIns.put(dsppartnersEndpoint, {
      ...params,
    })
  }

  createDsppartner(dsppartner) {
    const dsppartnersEndpoint = `${this.jwtConfig.dsppartnersEndpoint}`
    return this.axiosIns.post(dsppartnersEndpoint, {
      dsppartner,
    })
  }

  deleteDsppartner(id) {
    const dsppartnersEndpoint = `${this.jwtConfig.dsppartnersEndpoint}/${id}`
    return this.axiosIns.delete(dsppartnersEndpoint)
  }

  getDsppartnerStatsByType(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.dsppartnerstatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  prebidPartners({ page, limit }) {
    const prebidPartnersEndpoint = `${this.jwtConfig.prebidPartnersEndpoint}?page=${page}&limit=${limit}`
    return this.axiosIns.get(prebidPartnersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  createPrebidPartner(prebidpartner) {
    const prebidPartnersEndpoint = `${this.jwtConfig.prebidPartnersEndpoint}`
    return this.axiosIns.post(prebidPartnersEndpoint, {
      prebidpartner,
    })
  }

  deletePrebidPartner(id) {
    const prebidPartnersEndpoint = `${this.jwtConfig.prebidPartnersEndpoint}/${id}`
    return this.axiosIns.delete(prebidPartnersEndpoint)
  }

  getPrebidPartner(id) {
    const prebidPartnersEndpoint = `${this.jwtConfig.prebidPartnersEndpoint}/${id}`
    return this.axiosIns.get(prebidPartnersEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setPrebidPartner(id, params) {
    const prebidPartnersEndpoint = `${this.jwtConfig.prebidPartnersEndpoint}/${id}`
    return this.axiosIns.put(prebidPartnersEndpoint, {
      ...params,
    })
  }

  getPrebidPartnerStatsByDate(id, params = null) {
    return this.axiosIns.get(`${this.jwtConfig.prebidPartnersStatsEndpoint}/${id}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  currencies() {
    return this.axiosIns.get(this.jwtConfig.currenciesEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  setCurrency(id, params) {
    const currenciesEndpoint = `${this.jwtConfig.currenciesEndpoint}/${id}`
    return this.axiosIns.put(currenciesEndpoint, {
      ...params,
    })
  }

  reports(params) {
    return this.axiosIns.get(this.jwtConfig.reportsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  reportsexport(params) {
    return this.axiosIns.get(this.jwtConfig.reportsexportEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  async refreshTokenPnx() {
    const newTokens = await fetch(this.jwtConfig.refreshEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: this.getRefreshToken(),
      }),
    })
      .then(response => response.json())
      .then(data => data)
    return newTokens
  }

  refreshToken() {
    const args = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken(),
    })
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint,
      args, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
  }

  getCriteoStats(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.criteostatsapiEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  getWidgetPreview(preview) {
    const widgetPreviewV2 = `${this.jwtConfig.widgetPreviewV2}`
    return this.axiosIns.post(widgetPreviewV2, {
      ...preview,
    })
  }

  getNotifications() {
    return this.axiosIns.get(this.jwtConfig.notificationsEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  uploadAdsenseIncomes(formData) {
    const { importAdsenseIncomesEndpoint } = this.jwtConfig
    return this.axiosIns.post(
      importAdsenseIncomesEndpoint,
      formData,
    )
  }

  approveAdsenseIncomes(objToSend) {
    const { approveAdsenseIncomesEndpoint } = this.jwtConfig
    return this.axiosIns.post(approveAdsenseIncomesEndpoint, { ...objToSend })
  }

  getAdsenseIncomes({ page, limit }) {
    const adsenseIncomesEndpoint = `${this.jwtConfig.adsenseIncomesEndpoint}?page=${page}&limit=${limit}`
    return this.axiosIns.get(adsenseIncomesEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getAdsenseIncome(id) {
    const adsenseIncomeEndpoint = `${this.jwtConfig.adsenseIncomesEndpoint}/${id}`
    return this.axiosIns.get(adsenseIncomeEndpoint, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getDbd(params = null) {
    return this.axiosIns.get(`${this.jwtConfig.dbdEndpoint}`, {
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6',
        Authorization: `Bearer ${this.getToken()}`,
      },
      params,
    })
  }

  siteNamesById(ids = []) {
    const pixelsEndpoint = `${this.jwtConfig.siteNameByIdEndpoint}`
    return this.axiosIns.post(pixelsEndpoint, {
      ids,
    })
  }

  partnerNamesById(ids = []) {
    const pixelsEndpoint = `${this.jwtConfig.partnerNameByIdEndpoint}`
    return this.axiosIns.post(pixelsEndpoint, {
      ids,
    })
  }

  campaignNamesById(ids = []) {
    const pixelsEndpoint = `${this.jwtConfig.campaignNameByIdEndpoint}`
    return this.axiosIns.post(pixelsEndpoint, {
      ids,
    })
  }
}
