export default [
  {
    path: '/landings',
    name: 'dashboard-landings',
    component: () => import('@/views/dashboard/landings/LandingList.vue'),
  },
  {
    path: '/users/:userId/landings',
    name: 'dashboard-user-landings',
    component: () => import('@/views/dashboard/landings/LandingList.vue'),
  },
  {
    path: '/users/:userId/landings/:landingId/edit',
    name: 'dashboard-landing-edit',
    component: () => import('@/views/dashboard/landings/LandingEdit.vue'),
  },
  {
    path: '/users/:userId/landings/:landingId/new',
    name: 'dashboard-landing-new',
    component: () => import('@/views/dashboard/landings/LandingNew.vue'),
  },
]
